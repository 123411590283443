import { createContext, useContext } from 'react';
import type { Tracker } from '../types';

export const TrackerContext = createContext<Tracker | undefined>(undefined);

export const useTracker = (): Tracker => {
    const ctx = useContext(TrackerContext);

    if (!ctx) {
        throw new Error('useTracker is being used outside the context.');
    }

    return ctx;
};
